// import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import es from 'vuetify/es5/locale/es';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
// import colors from 'vuetify/es5/util/colors';

Vue.use(Vuetify);

// SARICO DISTRI

export default new Vuetify({
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				// primary: colors.blueGrey.darken3,	// #37474F #6D4C41
				// secondary: colors.yellow.darken3,	// #F9A825 #FFB300
				// accent: colors.orange.accent3,		// #FF6D00 #FF5722
				// featured: colors.amber.lighten5,	// #FFF8E1

				 primary: colors.amber.darken1,		// #FFB300
				 secondary: colors.brown.darken1,	// #6D4C41
				 accent: colors.deepOrange.base,		// #FF5722
				 featured: colors.amber.lighten5,	// #FFF8E1

				// error:		#FF5252
				// warning:	#FFC107
				// info:		#2196F3
				// success:	#4CAF50
			},
			// default: {
			// 	primary: colors.amber,									// #FFC107
			// 	secondary: colors.brown.darken2,				// #6D4C41
			// 	accent: colors.teal,										// #009688
			// 	error: colors.red.base,									// #F44336
			// 	warning: colors.orange.base,						// #FF9800
			// 	info: colors.cyan.base,									// #00BCD4
			// 	success: colors.green.base,							// #4CAF50
			// 	tableHeader: colors.grey.lighten2,			// #E0E0E0
			// 	pageHeader: colors.grey.darken1,
			// 	homeFooter: colors.grey.lighten2,
			// 	percentageRate: colors.amber.darken4,
			// 	percentageType: colors.amber,
			// },
		},
	},
	lang: {
		locales: { es },
		current: 'es',
	},
	icons: {
		iconfont: 'mdi'
	}
	
});
